/* Import Bootstrap & Font-Awesome */
@import "node_modules/bootstrap-sass/assets/stylesheets/_bootstrap";
@import "public/fontawesome/css/fw5.1/all";
@import "public/fontawesome/css/fw5.1/brands";
@import "public/fontawesome/css/fw5.1/light";
@import "public/fontawesome/css/fw5.1/regular";
@import "public/fontawesome/css/fw5.1/solid";
@import "node_modules/aos/dist/aos";
@import "node_modules/plyr/dist/plyr";

/* Import fullpage.js */
@import "node_modules/fullpage.js/dist/jquery.fullpage";

$home-vision-height: 700px;
$page-vision-height: 400px;
$header-height: 60px;

@font-face {
  font-family: Consolas;
  src: url("fonts/Consolas.ttf");
}

@font-face {
  font-family: Raleway;
  src: url("fonts/Raleway-Regular.ttf");
}

@font-face {
  font-family: Raleway;
  font-weight: bold;
  src: url("fonts/Raleway-Bold.ttf");
}

.btn-laravel{
  background: #F4645F;
  color: #fff;
  transition: all 0.3s;
  &:hover{
    background: #c8524e;
    color: #fff;
    transition: all 0.3s;
  }
  i{
    margin-right: 5px;
  }
}
.btn-laravel-2{
  background: #bc423e;
  color: #fff;
  transition: all 0.3s;
  &:hover{
    background: #912622;
    color: #fff;
    transition: all 0.3s;
  }
  i{
    margin-right: 5px;
  }
}
.btn-laravel-white{
  background: #fff;
  border: 1px solid #F4645F;
  color: #F4645F;
  &:hover{
    background: #F4645F;
    color: #fff;
  }
}
.btn-noradius{
  border-radius: 0px;
}

body{
  font-size: 14px;
  font-family: Raleway,PingFangTC,"微軟正黑體","Open Sans",Lato,Noto,Helvetica,AvenirNext-Regular,Roboto,HelveticaNeue-Light,Arial,"Microsoft JhengHei",sans-serif;
  &.home{
    padding-top: $home-vision-height + $header-height;
  }
  &.page{
    padding-top: $page-vision-height + $header-height;
  }
}
ul,ol{
  padding: 0px;
  margin: 0px;
}
header{
  background: #ea503b;
  position: fixed;
  height: $header-height;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 100;
  ul{
    li{
      display: inline-block;
      font-size: 13px;
      line-height: 14px;
      height: 60px;
      letter-spacing: 0.5px;
      a{
        color: #fff;
        &:hover{
          color: #e4c3a3;
          text-decoration: none;
        }
      }
    }
  }
  .main-nav{
    text-align: center;
    display: block;
    margin: 0 auto;
    li{
      letter-spacing: 1px;
      padding: 23px 5px;
      &:before{
        content: '/  ';
        color: #ea9189;
        margin-right: 5px;
      }
      &:first-child:before{
        content: '';
      }
    }
  }
  .social{
    position: absolute;
    right: 10px;
    top: 0px;
    >li{
      padding: 23px 6px;
      font-size: 15px;
      text-shadow: 2px 2px 0px #6d251b;
    }
    .dropdown{
      .lang-switch{
        padding: 0px 5px;
        font-size: 14px;
        background: #F4645F;
        color: #fff;
        border: 1px solid #ff6963;
        box-shadow: 2px 2px 0px #7b3230;
      }
      .dropdown-menu{
        >li{
          display: block;
          height: auto;
          >a{
            text-shadow: none;
          }
        }
      }
    }
  }
}

footer{
  position: relative;
  z-index: 2;
  background: #fff;
  padding: 40px 0;
  border-top: 2px solid #d5a0a0;
  .qwan-logo{
    width: 150px;
    margin-bottom: 5px;
    position: relative;
    top: -5px;
  }
  a{
    color: #000;
    text-decoration: none;
    &:hover{
      text-decoration: none;
    }
  }
  .title{
    font-size: 15px;
    font-weight: 600;
    color: #ec6d65;
    margin-bottom: 14px;
    a{
      color: #ec6d65;
      &:hover{
        color: #ec6d65;
      }
    }
  }
  .footer-block{
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    p{
      margin-bottom: 6px;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
}

#clock{
  position: relative;
  margin: 30px 0 30px 0;
  left: -20px;
}

.text-logo{
  //margin-top: 50px;
  h1{
    font-family: Raleway;
    font-size: 64px;
    color: #F4645F;
    font-weight: bold;
    margin-bottom: 5px;
  }
  h2{
    font-family: Raleway;
    font-size: 40px;
    color: #000;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 5px;
    b{
      font-family: Consolas;
      font-size: 32px;
      color: #999;
      position: relative;
      top: -4px;
    }
  }
  .subtitle{
    font-family: Consolas;
    width: 385px;
    height: 38px;
    background: #F4645F;
    .typing{
      display: inline-block;
      padding: 5px 10px;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }
    .typed-cursor{
      display: none;
    }
    b{
      font-family: Consolas;
      font-weight: normal;
    }
  }
}
.page-vision{
  z-index: 1;
  background-color: #fff5ea;
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-image: url('img/page-vision-bg.png');
  position: fixed;
  width: 100%;
  height: $page-vision-height;
  top: 60px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  &.solid{
    background: #F4645F;
    .text-logo{
      h1{
        color: #fff;
      }
      h2{
        color: #fff;
        b{
          color: #fff;
        }
      }
      .subtitle{
        background: #fff;
        .typing {
          color: #F4645F;
        }
      }
    }
  }
}
.home-vision{
  //background-image: url("img/2018-visual-home.png");
  //background-size: 1000px;
  //background-position: center 250px;
  background-color: #fff8f0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('img/2018-visual-bg.jpg');
  @media (max-width: 468px){
    background-image: url('img/2018-visual-bg-m.png');
  }
  z-index: 1;
  position: fixed;
  width: 100%;
  height: $home-vision-height;
  top: 60px;
  left: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.home-location{
  font-size: 16px;
  letter-spacing: 1px;
  color: #555;
  line-height: 22px;
  margin-top: 15px;
  position: relative;
  z-index: 10;
  b{
    font-weight: normal;
    color: #F4645F;
  }
}

.home-about{
  background: #F4645F;
  padding: 50px 0px;
  position: relative;
  z-index: 2;
  p{
    color: #fff;
    font-size: 14px;
    line-height: 1.8em;
    letter-spacing: 1px;
  }
}

.home-topic{
  padding: 60px 0;
  position: relative;
  z-index: 2;
  background: #fff;
  .home-topic-item{
    padding: 10px 20px;
  }
  .home-topic-img{
    padding: 20px;
    img{
      border-radius: 50%;
      box-shadow: 5px 5px 0px #9b4947;
      border: 2px solid rgba(155, 73, 71, 0.27);
    }
  }
  .home-topic-info{
    text-align: center;
    h3{
      font-size: 20px;
      color: #000;
      border-bottom: 1px solid #2d343c;
      margin: 10px -10px 20px -10px;
      padding-bottom: 15px;
      span{
        font-family: Consolas;
        color: #F4645F;
        display: block;
      }
    }
    .topic-time{
      font-size: 18px;
      font-weight: bold;
    }
    p{
      font-size: 14px;
      letter-spacing: 1px;
      color: #222;
    }
    .topic-location{
      border: 1px solid #F4645F;
      padding: 5px 10px;
      font-size: 13px;
      span{
        color: #F4645F;
      }
    }
  }
}

.home-map{
  height: 400px;
  position: relative;
  z-index: 2;
  width: 100%;
  border-top: 2px solid #d5a0a0;
}

.page-content{
  min-height: 1000px;
  position: relative;
  z-index: 2;
  background: #fff;
  border-top: 5px solid #F4645F;
}
.page-title{
  background: rgba(244, 100, 95, 0.638);
  color: #fff;
  font-size: 26px;
  letter-spacing: 3px;
  padding: 12px 20px;
  &.agenda-title{
    background: rgba(236, 53, 36, 0.84);
  }
}

.schedule-item{
  text-align: left;
  padding: 15px;
  margin: 40px 5px;
  border: 1px solid #f49591;
  box-shadow: 3px 3px 0px #c75e5b;
  .plyr, .schedule-img{
    margin-bottom: 14px;
    border: 2px solid #f49591;
  }
  h3{
    font-size: 16px;
    margin: 0 0 15px 0;
    letter-spacing: 1px;
    font-weight: bold;
    a{
      color: #000;
      &:hover{
        text-decoration: none;
      }
    }
  }
  h4{
    font-size: 14px;
    color: #000;
    line-height: 1.3em;
    span{
      color: #76236b;
    }
  }
  p{
    line-height: 1.86;
    a{
      color: #000;
      text-decoration: none;
    }
  }
  .level{
    color: #000;
    font-size: 14px;
    i{
      margin: 0 3px;
      color: #f4ada0;
      &.fill{
        color: #F4645F;
      }
    }
  }
}

.speakers{
  padding: 30px 0;
}
.speaker-item{
  padding: 0 17px;
  margin: 15px 0;
  h1{
    font-size: 36px;
    letter-spacing: 1.1px;
    margin: 10px 0px;
    span.eng-name{
      font-size: 20px;
      margin-left: 10px;
      font-weight: bold;
    }
  }
  h2{
    font-size: 14px;
    margin: 25px 0 10px 0;
    line-height: 22px;
  }
  .speaker-img{
    margin-bottom: 15px;
    box-shadow: 3px 3px 0px #c75e5b;
  }
  p.intro{
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 1px;
    display: inline-table;
  }
  .speaker-social-icons{
    margin-bottom: 7px;
    a{
      font-size: 18px;
      margin-right: 8px;
      color: #F4645F;
      &:hover{
        text-decoration: none;
      }
    }
  }
}

.embed-responsive-custom{
  padding-bottom: 31%;
}

.agenda-content{
  margin: 40px 0;
}
.agenda-info{
  padding: 0 20px;
  h1{
    font-size: 36px;
    letter-spacing: 1.1px;
    margin: 10px 0px 20px 0px;
    span.eng-name{
      font-size: 20px;
      margin-left: 10px;
      font-weight: bold;
    }
  }
  h2{
    font-size: 14px;
    margin: 25px 0 10px 0;
    line-height: 22px;
  }
  p{
    font-size: 16px;
    line-height: 1.95em;
  }
}

.plyr--video{
  .plyr__controls{
    background: linear-gradient(rgba(207, 207, 207, 0), rgba(165, 34, 31, 0.8));  }
}
.plyr__control--overlaid{
  background: rgba(234, 80, 59, 0.85);
}
.plyr__control--overlaid:focus, .plyr__control--overlaid:hover{
  background: #ea503b;
}
.plyr--full-ui input[type=range]{
  color: #ea503b;
}
.plyr--video .plyr__controls .plyr__control.plyr__tab-focus, .plyr--video .plyr__controls .plyr__control:hover, .plyr--video .plyr__controls .plyr__control[aria-expanded=true]{
  background: #ea503b;
}

.giant-title{
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: normal;
  line-height: 1.87em;
  margin: 40px 0;
}
.sponsor-level{
  color: #ec6d65;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 35px;
  text-align: center;
  border-bottom: 1px solid #EC6D65;
  padding-bottom: 5px;
  span{
    font-size: 30px;
  }
  i{
    color: #212624;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    font-style: normal;
  }
}
.sponsor-item{
  margin-bottom: 30px;
  h4{
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    a{
      color: #000;
      &:hover{
        text-decoration: none;
      }
    }
  }
  p{
    font-size: 14px;
    line-height: 1.86;
  }
  img{
    border: 1px solid #ddd;
    width: 100%;
  }
}

.bg-sponsor-top-text{
  background: #FFEADF;
  background: rgba(255, 240, 230, 0.65);
  margin-bottom: 40px;
}

#mMenu{
  .close{
    font-size: 30px;
    float: none;
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 1;
    i{
      color: #fff;
    }
  }
  .modal-dialog{
    width: 100%;
    height: 100%;
    margin: 0px;
  }
  .main-nav-m{
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    >li{
      margin: 3px 0;
      >a{
        color: #fff;
        font-size: 5vh;
        &:hover{
          text-decoration: none;
          color: #F4645F;
        }
      }
    }
  }
}

.modal-backdrop.in{
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.venue-content{
  margin: 50px 0;
  h2{
    font-size: 24px;
    font-weight: 600;
    line-height: 1.21;
    margin-bottom: 22px;
  }
  h3{
    font-size: 18px;
    font-weight: 600;
    line-height: 1.61;
    color: #ec6d65;
    margin-bottom: 20px;
  }
  p{
    font-size: 14px;
    line-height: 1.86;
  }
}
.venue{
  .venue-image{
    height: 400px;
    background-size: cover;
    background-position: center;
  }
  .home-map{
    border-bottom: 2px solid #d5a0a0;
  }
}

.letter{
  .nav-pills{
    >li{
      >a, >a:focus, >a:hover{
        background: #f8f8f8;
        color: #555;
        border-radius: 0px;
      }
      &.active{
        >a, >a:focus, >a:hover{
          background: #F4645F;
          color: #fff;
          transition: all 0.3s;
        }
      }
    }
  }
  .tab-content{
    margin-bottom: 50px;
    .tab-pane{
      position: relative;
      .btn-copy{
        position: absolute;
        top: 15px;
        right: 15px;
        &.copied{
          background: #000;
          color: #fff;
        }
      }
      >div{
        padding: 30px 50px;
        box-shadow: 0px 5px 10px #666;
        margin-top: 30px;
        h3{
          font-size: 20px;
          letter-spacing: 0.5px;
          font-weight: normal;
          line-height: 1.87em;
          margin: 20px 0;
          padding-bottom: 10px;
          border-bottom: 3px solid #F4645F;
        }
        p{
          line-height: 1.87;
          font-size: 16px;
        }
        ul,ol{
          margin-bottom: 15px;
          padding-left: 20px;
          li{
            font-size: 16px;
            line-height: 1.8;
          }
        }
      }
    }
  }
}
.agenda-table-wrapper{
  background: #fff4ef;
  padding: 50px 0;
  table.agenda-table{
    border-bottom: 1px solid #ddd;
    tr.resttime{
      td, th{
        background: #f6d9d0;
        h4{
          margin: 1px 0;
          color: #ec6d65;
        }
      }
    }
    td, th{
      text-align: center;
      background: #fff;
      &.right-border{
        border-right: 1px solid #ddd;
      }
      .agenda-links{
        margin: 7px 0;
      }
      h3{
        font-size: 14px;
        margin: 5px 0 5px 0;
        letter-spacing: 1px;
        font-weight: bold;
        a{
          color: #000;
        }
      }
      h4{
        font-size: 14px;
        color: #000;
        line-height: 1.3em;
        margin: 4px 0 4px 0;
        span{
          color: #76236b;
        }
      }
      p{
        margin: 0;
      }
      .level{
        i{
          margin: 0 2px;
          color: #f4ada0;
          &.fill{
            color: #F4645F;
          }
        }
      }
    }
  }
}

.recruit-page{
  padding: 50px 0;
}

.recruit-item{
  box-shadow: 3px 3px 0px #c75e5b;
  border: 1px solid #f49591;
  padding: 25px;
  position: relative;
  transition: all 0.2s;
  margin-bottom: 40px;
  top: 0px;
  left: 0px;
  .job-title{
    font-size: 16px;
    margin: 0 0 15px 0;
    letter-spacing: 1px;
    font-weight: bold;
  }
  .company-img{
    margin: 15px 0;
  }
  .job-source-company{
    position: relative;
    background: #c75e5b;
    color: #fff;
    display: inline-block;
    /* top: 0px;
    left: 0px; */
    padding: 3px 7px;
    font-size: 13px;
    text-align: center;
    margin-bottom: 5px;
  }
  .job-desc{
    line-height: 1.86;
    margin-bottom: 15px;
  }
  &:hover{
    box-shadow: 6px 6px 0px #c75e5b;
    top: -3px;
    left: -3px;
    transition: all 0.2s;
  }

}

.repo-card .status{
  font-size: 13px !important;
}


.packages{
  //padding: 50px 0;
}
.package-item{
  padding: 15px 15px 60px 15px;
  position: relative;
  border: 1px solid #f49591;
  box-shadow: 3px 3px 0px #c75e5b;
  margin-bottom: 35px;
  .package-header{
    margin-bottom: 10px;
  }
  .package-author-img{
    width: 50px;
    height: 50px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: top;
    img{
      width: 50px;
      height: 50px;
      border-radius: 10px;
    }
  }
  .package-star-button{
    //display: inline-block;
    width: 50px;
    position: absolute;
    top: 10px;
    right: 10px;
    .btn{
      width: 100%;
      text-align: center;
      height: 24px;
      background: #76236b;
      &:hover{
        background: darken(#76236b,10%);
      }
      i{
        margin: 0px;
        font-size: 12px;
      }
    }
  }
  .package-title-wrapper{
    display: inline-block;
    width: calc(100% - 110px);
    vertical-align: top;
  }
  .package-title{
    display: block;
    font-size: 18px;
    line-height: 1.4;
    font-weight: bold;
    a{
      color: #F4645F;
    }
  }
  .package-language{
    font-size: 10px;
    margin-left: 3px;
    color: #797979;
  }
  .package-author{
    font-size: 14px;
    a{
      color: #000;
      text-decoration: underline;
    }
  }
  .package-description{
    margin: 0 5px 0 0;
    font-size: 18px;
    clear: both;
    word-wrap: break-word;
    a{
      color: #F4645F;
    }
  }
  .package-counts{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    border-top: 1px solid #e54b54;
    background: rgba(244, 100, 95, 0.08);
    >div.package-star-count-div{
      width: 33.33%;
      float: left;
      height: 40px;
      line-height: 40px;
      //background: #e54b54;
      //color: #fff;
      text-align: center;
      font-weight: bold;
      font-size: 15px;
      border-right: 1px solid #e54b54;
      &:last-child{
        border-right: none;
      }
      span{
        margin-right: 5px;
        font-weight: normal;
        font-size: 12px;
        color: #000;
      }
    }
    >div.package-star-add{
      color: #cd4634;
      border-right: none;
      font-size: 18px;
    }
  }
}

$home-vision-height-m: 550px;
$page-vision-height-m: 350px;
@media (max-width: 767px){

  .venue {
    .venue-image {
      height: 300px;
    }
  }

  .letter{
    .tab-content{
      .tab-pane{
        >div{
          padding: 20px 30px;
        }
      }
    }
  }

  body{
    &.home{
      padding-top: $home-vision-height-m;
    }
    &.page{
      padding-top: $page-vision-height-m;
    }
  }

  .modal-open{
    background: #fff;
    padding-right: 0px !important;
    .home-vision{
      //width: calc(100% - 15px);
    }
  }

  .home-vision{
    height: $home-vision-height-m;
    top: 0px;
    .container{
      width: 100%;
    }
  }

  .page-vision{
    height: $page-vision-height-m;
    top: 0px;
    background-position: center center;
    .container{
      width: 100%;
    }
  }

  .text-logo{
    text-align: center;
    h1{
      font-size: 13vw;
      font-weight: bold;
    }
    h2{
      font-size: 8vw;
      letter-spacing: 0px;
    }
    .subtitle{
      width: 100%;
      .typing{
        font-size: 4.5vw;
      }
    }
  }

  .home-location{
    font-size: 3.5vw;
    #clock{
      text-align: left;
      left: inherit;
      margin: 30px auto;
    }
  }

  .m-menu{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 30px;
    z-index: 10;
    .m-menu-toggle{
      background: none;
      border: none;
      outline: none;
      i{
        font-size: 22px;
        padding: 10px;
        color: #F4645F;
      }
    }
  }

  .home-ticket-button{
    text-align: center;
  }

  footer {
    padding: 20px 0px 5px 0px;
    .footer-block{
      margin-bottom: 30px;
      line-height: 1.6em;
      .title{
        margin-bottom: 6px;
      }
      p{
        margin-bottom: 5px;
      }
    }
  }
}

